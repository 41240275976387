import MitekCheckBackExplanationComponent from './Views/Information/MitekCheckBackExplanationComponent'
import MitekCheckCheckDesktop from './Views/Information/MitekCheckCheckDesktop'
import MitekCheckCompletedComponent from './Views/Information/MitekCheckCompletedComponent'
import MitekCheckDesktop from './MitekCheckDesktop'
import MitekCheckFrontExplanationComponent from './Views/Information/MitekCheckFrontExplanationComponent'
import MitekCheckLoadingComponent from './Views/Information/MitekCheckLoadingComponent'
import MitekCheckLoadingComponentDesktop from './Views/Information/MitekCheckLoadingComponentDesktop'
import MitekCheckManualCaptureBackComponent from './Views/Scanning/MitekCheckManualCaptureBackComponent'
import MitekCheckManualCaptureFrontComponent from './Views/Scanning/MitekCheckManualCaptureFrontComponent'
import MitekCheckManualCaptureSelfieComponent from './Views/Scanning/MitekCheckManualCaptureSelfieComponent'
import MitekCheckManualCaptureSignatureComponent from './Views/Scanning/MitekCheckManualCaptureSignatureComponent'
import MitekCheckSelfieExplanationComponent from './Views/Information/MitekCheckSelfieExplanationComponent'
import MitekCheckSignatureExplanationComponent from './Views/Information/MitekCheckSignatureExplanationComponent'
import MitekCheckSuccessComponent from './Views/Information/MitekCheckSuccessComponent'
import MitekDocumentSelectionComponent from './Views/Scanning/MitekCheckDocumentSelectionComponent'
import MitekErrorPage from './Views/Errors/MitekErrorPage'
import MitekMissingDataComponent from './Views/MitekMissingDataComponent'
import MitekScanBackComponent from './Views/Scanning/MitekScanBackComponent'
import MitekScanFrontComponent from './Views/Scanning/MitekScanFrontComponent'
import MitekScanSelfieComponent from './Views/Scanning/MitekScanSelfieComponent'
import MitekScanSignatureComponent from './Views/Scanning/MitekScanSignatureComponent'
import MitekSubmitComponent from './Views/MitekSubmitComponent'
import { appendRequestCurrentPageCallback } from './Utils/appendToRequestCallback'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { DevLog } from '../../../utils/DevLog'
import { DriversLicenseMissingDataMockState } from './Mocking/DriversLicenseMissingDataMockState'
import { ELogType } from '../../../Enums/ELogType'
import { EMitekErrorType } from '../../../Enums/EMitekErrorType'
import { EMitekPageType } from '../../../Enums/EMitekPageType'
import { getMitekSettings, postMitekCheck, postMitekCheckToOcr, postTransactionProgressLog } from '../../../Services/Backend'
import { getStartingPage } from './Utils/getStartingPage'
import { Header, Icon, Modal, Segment } from 'semantic-ui-react'
import { IMitekResponse, MitekRequestBuilder } from '../../../Types'
import { IMitekSettings } from '../../../models/flow/settings/IMitekSettings'
import { isDesktop, isIPad13, isTablet } from 'react-device-detect'
import { MitekCheckAgentHandoff } from './Views/Information/MitekCheckAgentHandoff'
import { StateDispatch, StateValue } from '../../Context'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../Hooks/useDebounce'
import { useTranslation } from 'react-i18next'
import { useView } from '../../../Hooks/useView'
import './MitekCheck.css'
import './View.scss'
import MitekCheckManualCaptureQrComponent from './Views/Scanning/MitekCheckManualCaptureQrComponent'
import MitekScanQrComponent from './Views/Scanning/MitekScanQrComponent'
import MitekCheckQrExplanationComponent from './Views/Information/MitekCheckQrExplanationComponent'
import { MitekAutoCaptureResponse } from './Types/Types'

export type MitekCheckProps = {
  props: any;
  combinedCheckProps?: any
  isLandscape?: boolean,
  isPortrait?: boolean
};

export const MitekCheckComponent = (props: MitekCheckProps) => {
  const mitekScienceSDK = (window as any).mitekScienceSDK;
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);

  const { t } = useTranslation(state.org.theme.config.translationKey);

  const [mitekSettings, setMitekSettings] = useState<IMitekSettings | undefined>();
  const [countDown, setCountDown] = useState(0); // Used to enable "Manual Capture" button; updates every second,
  const [currentPage, setCurrentPage] = useView<EMitekPageType>(getStartingPage());
  const [wasFileUpload, setWasFileUpload] = useState(false);

  const [hint, setHint] = useState(""); // Hint shown to the user on manual & auto capture pages. Auto capture pages use a debounced function (isVisible) to prevent the hint from being shown too often.
  const [visible, setIsVisible] = useState(false);
  const debouncedIsVisible: boolean = useDebounce<boolean>(visible, 500);
  const inputFile = useRef<any>(null) // Manual capture file input
  const [loadingCamera, setLoadingCamera] = useState(false);

  const [request, setRequest] = useState<MitekRequestBuilder | any>({ type: "PASSPORT", front: undefined, selfie: undefined });
  const [response, setResponse] = useState<IMitekResponse | undefined>(undefined);

  const [type, setType] = useState<"PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT">();

  useEffect(() => {
    localStorage.setItem("DOCUMENT_TYPE", type ?? "");
  }, [type])

  // A debounced value of isVisible is used to show hints on auto-capture pages.
  useEffect(() => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 2000);

  }, [hint])

  // The countdown used to enable the "manual capture" button.
  useEffect(() => {
    if (countDown !== 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    }
  }, [countDown])

  // Disables the mitekSDK when the page is changed.
  useEffect(() => {
    try {
      mitekScienceSDK.cmd("SDK_STOP");
    } catch (e) {
      console.error(e);
      console.log("SDK_STOP failed");
    }
    setHint("");
    setCountDown(0);
    if (currentPage === EMitekPageType.SCAN_FRONT || currentPage === EMitekPageType.SCAN_BACK || currentPage === EMitekPageType.SCAN_SIGNATURE) {
      setLoadingCamera(true);
      setTimeout(startAutoCapture, 1000);
      setCountDown(8);
    }

    if (currentPage === EMitekPageType.SCAN_SELFIE) {
      setLoadingCamera(true);
      setTimeout(() => startAutoCapture("SELFIE"), 1000);
      setCountDown(8);
    }

    if (currentPage === EMitekPageType.SCAN_QR) {
      setLoadingCamera(true);
      setTimeout(() => startAutoCapture("QR_BARCODE"), 1000);
      setCountDown(8);
    }

    if (currentPage === EMitekPageType.MANUAL_CAPTURE_FRONT || currentPage === EMitekPageType.MANUAL_CAPTURE_BACK || currentPage === EMitekPageType.MANUAL_CAPTURE_SELFIE || currentPage === EMitekPageType.MANUAL_CAPTURE_SIGNATURE || currentPage === EMitekPageType.MANUAL_CAPTURE_QR) {
      setLoadingCamera(false);
      setCountDown(5);
    }
    setHint("")
  }, [currentPage]);

  // Allows us to force static pages to be rendered & get MitekSettings.
  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.MitekCheckEntered });

    getMitekSettings(state.ctxId, props.combinedCheckProps?.service ?? 8).then(res => {
      setMitekSettings(res.data);
      if (res.data.isAgentCall) {
        setCurrentPage(EMitekPageType.GENERAL_AGENT)
      }
    });

    const devPage = localStorage.getItem("FORCE_PAGE");
    if (devPage && window.location.href.includes("localhost")) {
      setRequest(DriversLicenseMissingDataMockState.request);
      setResponse(DriversLicenseMissingDataMockState.response);
      setMitekSettings(DriversLicenseMissingDataMockState.mitekSetting);
      setCurrentPage(parseInt(devPage));
    }

    return () => {
      mitekScienceSDK.cmd("SDK_STOP");
    };
  }, []);

  // Appends the image to the request.
  const appendToRequest = (mitekResponse: MitekAutoCaptureResponse) => {

    if (request.front === undefined) {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.MitekCheckTookFirstPicture });
      setRequest({ ...request, front: mitekResponse.response.imageData });
      setCurrentPage(appendRequestCurrentPageCallback(currentPage, mitekSettings!, type!, mitekResponse));
    } else if (request.back === undefined && (type !== "PASSPORT" || (type === "PASSPORT" && mitekResponse?.response?.mrz?.countryCode == "NLD" && !mitekResponse?.response?.mrz?.hasOptionalData)) || mitekResponse.response.code !== undefined) {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.MitekCheckTookSecondPicture });
      setRequest({ ...request, back: mitekResponse.response.imageData, code: mitekResponse.response?.code });
      setCurrentPage(appendRequestCurrentPageCallback(currentPage, mitekSettings!, type!, mitekResponse));
    } else {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.MitekCheckTookSelfie });
      setRequest({ ...request, selfie: mitekResponse.response.imageData });
      setCurrentPage(appendRequestCurrentPageCallback(currentPage, mitekSettings!, type!, mitekResponse));
    }
  };

  const handleFileSelect = async (e: any, documentType?: "SELFIE" | "QR_BARCODE") => {
    setWasFileUpload(true);
    setLoadingCamera(true);
    var files = e.target.files as FileList;
    if (files[0].size > 5242880) {
      setHint(t("Mitek.File_Too_Big"));
      setLoadingCamera(false);
    } else {
      const promise = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      setLoadingCamera(false);
      processManualFrame(promise as string, documentType);
    }
  }

  const processManualFrame = (img: string, documentType?: "SELFIE" | "QR_BARCODE") => {
    let frameType;

    if (documentType) {
      frameType = documentType;
    } else {
      frameType = type === "PASSPORT" ? "PASSPORT" : "DL_FRONT";
    }

    mitekScienceSDK.cmd("PROCESS_FRAME", {
      mode: "DIRECT",
      documentType: frameType,
      mitekSDKPath: "../../../mitek/mitekSDK/",
      options: { frame: img, license: process.env.REACT_APP_MITEK_KEY },
    });

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", function (result: any) {
      setLoadingCamera(false);
    });

    mitekScienceSDK.on("IMAGE_CAPTURED", function () {
    });

    mitekScienceSDK.on("FRAME_PROCESSING_STARTED", function () {
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", function (result: MitekAutoCaptureResponse) {
      console.log(result);

      setLoadingCamera(false);
      if (result.response.status !== "failure") {
        appendToRequest(result);
        DevLog(result);
      } else {
        setHint(t("Mitek.Hints." + result?.response?.warnings?.key) ?? "Daar ging iets mis, zou je het opnieuw kunnen proberen?");
      }
    });
    mitekScienceSDK.on("SDK_ERROR", function (err: any) {
      setLoadingCamera(false);
      setHint(JSON.stringify(err));
    });
  }


  // Make sure the auto capture is stopped before starting this; otherwise, it will cause an error. Currently this is done in an useEffect hook.
  const startManualCapture = (documentType?: "SELFIE" | "QR_BARCODE") => {
    let autoCaptureType;
    if (documentType) {
      autoCaptureType = documentType;
    } else {
      autoCaptureType = type === "PASSPORT" ? "PASSPORT" : "DL_FRONT";
    }

    setLoadingCamera(true);
    mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
      mode: "MANUAL_CAPTURE",
      documentType: autoCaptureType,
      mitekSDKPath: "../../../mitek/mitekSDK/",
      preloadComponents: ['ALL'],
      options: {
        qualityPercent: 80,
        license: process.env.REACT_APP_MITEK_KEY,
      },
    });

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", function (result: any) {
      setLoadingCamera(false);
    });

    mitekScienceSDK.on("IMAGE_CAPTURED", function () {
    });

    mitekScienceSDK.on("FRAME_PROCESSING_STARTED", function () {
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", function (result: MitekAutoCaptureResponse) {
      setLoadingCamera(false);
      if (result.response.status !== "failure") {
        appendToRequest(result);
        DevLog(result);
      } else {
        setHint(t("Mitek.Hints." + result?.response?.warnings?.key) ?? "Daar ging iets mis, zou je het opnieuw kunnen proberen?");
      }
    });
    mitekScienceSDK.on("SDK_ERROR", function (err: any) {
      setLoadingCamera(false);
      setHint(JSON.stringify(err));
    });
  };

  const startAutoCapture = (documentType?: "SELFIE" | "QR_BARCODE") => {
    let autoCaptureType;
    if (documentType) {
      autoCaptureType = documentType;
    } else {
      autoCaptureType = type === "PASSPORT" ? "PASSPORT" : "DL_FRONT";
    }

    mitekScienceSDK.on("CAMERA_DISPLAY_STARTED", function (result: any) {
      setLoadingCamera(false);
    });

    mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", function (result: any) {
      if (result.key !== hint) {
        setHint(t("Mitek.Hints." + result.key));
      }
    });

    mitekScienceSDK.on("FRAME_PROCESSING_STARTED", function (result: any) {
    });

    mitekScienceSDK.on("FRAME_CAPTURE_RESULT", function (result: any) {
      if (result.response.status !== "failure") {
        appendToRequest(result);
      }
    });

    mitekScienceSDK.on("SDK_ERROR", function (err: any) {
      console.error(err);
      console.log("SDK_ERROR", err);
    });

    mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
      mode: "AUTO_CAPTURE",
      documentType: autoCaptureType,
      mitekSDKPath: "../../../mitek/mitekSDK/",
      options: {
        license: process.env.REACT_APP_MITEK_KEY,
        guidePaddingLevel: 1,
        qualityPercent: 80, // value between 50 to 100 recommended
        hintFrequencyMS: 1200, // how long the hint message displays (in MS) / note: 2400 recommended for screen readers
        hintAriaLive: 2, //screen reader setting for hint updates value (0=off / 1=polite / 2=assertive)
        hintMessageSize: 2, // set the size of the hint message (1=small / 2=medium / 3=large)
        faceDetectionLevel: 1, // determines how strict face detection is (1=lenient, 2=moderate, 3=strict)
        disableSmileDetection: false, // disable smile detection
        videoContainerId: "mitekContainer",
        videoRecordingEnabled: false, // (optionally) record the video session / returned as a dataURL
        audioRecordingEnabled: false, // (optionally) also record audio / only applies if videoRecordingEnabled = true)
        videoQuality: 70, // (optionally) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
        videoRecordingMessage: "Recording", // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
      },
    });
  };

  const onSuccess = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.MitekCheckCompleted });
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const postBackend = () => {
    const front = request.front.substring(request.front.indexOf(",") + 1);
    const back = request.back?.substring(request.back.indexOf(",") + 1);
    const selfie = request?.selfie?.substring(request.selfie.indexOf(",") + 1);

    let imageData: string[] = [];
    if (front !== undefined) {
      imageData = [...imageData, front];
    }
    if (back !== undefined) {
      imageData = [...imageData, back];
    }

    postMitekCheck({ IdDocuments: imageData, type: type!, selfie: selfie ?? null, ctxId: state.ctxId, wasFile: wasFileUpload, code: request.code }).then(res => {
      // postMitekCheckToOcr({ IdDocuments: (type === "PASSPORT" && !mitekSettings?.enablePassportSignature) ? [front] : [front, back], type: type!, selfie: selfie ?? null, ctxId: state.ctxId, wasFile: wasFileUpload }).then(res => {
      if (res.status === 200) {
        setResponse(res.data);
        if (res.data.success) {
          DevLog("Succes");
          if (mitekSettings?.appendMissingPropertiesDesktop) {
            DevLog("appendMissingPropertiesDesktop");
            if (props.props.match.params.handoff !== undefined) {
              DevLog("handoff = undefined")
              setCurrentPage(EMitekPageType.CHECK_DESKTOP);
            } else {
              setCurrentPage(EMitekPageType.ERROR_MISSING_DATA);
            }
          } else {
            setCurrentPage(isDesktop ? EMitekPageType.DESKTOP_APPEND_ERROR_MISSING_DATA : EMitekPageType.ERROR_MISSING_DATA);
          }
        } else {
          if ((res.data.reasons ?? []).includes(EMitekErrorType.AGENT_ASSIST)) {
            setCurrentPage(EMitekPageType.GENERAL_AGENT)
          } else if (res.data.settings!.appendMissingPropertiesAddition && (!(res.data.reasons as any).any((r: EMitekErrorType) => r !== EMitekErrorType.MISSING_DATA) && res.data.reasons!.includes(EMitekErrorType.MISSING_DATA))) {
            setCurrentPage(EMitekPageType.ERROR_MISSING_DATA);
          } else {
            setCurrentPage(EMitekPageType.ERROR)
          }
        }
      }
    }).catch((err: any) => {
      setCurrentPage(EMitekPageType.ERROR);
    });
  }

  const renderPage = () => {
    switch (currentPage) {
      case EMitekPageType.DOCUMENT_SELECTION:
        return <MitekDocumentSelectionComponent settings={mitekSettings} setCurrentPage={setCurrentPage} setType={setType} state={state} />
      case EMitekPageType.SCAN_FRONT:
        return <MitekScanFrontComponent countDown={countDown} debouncedIsVisible={debouncedIsVisible} hint={hint} loadingCamera={loadingCamera} mitekScienceSDK={mitekScienceSDK} setCurrentPage={setCurrentPage} state={state} />
      case EMitekPageType.SCAN_BACK:
        return <MitekScanBackComponent countDown={countDown} debouncedIsVisible={debouncedIsVisible} hint={hint} loadingCamera={loadingCamera} mitekScienceSDK={mitekScienceSDK} setCurrentPage={setCurrentPage} state={state} />
      case EMitekPageType.SCAN_QR:
        return <MitekScanQrComponent countDown={countDown} debouncedIsVisible={debouncedIsVisible} hint={hint} loadingCamera={loadingCamera} mitekScienceSDK={mitekScienceSDK} setCurrentPage={setCurrentPage} state={state} />
      case EMitekPageType.SCAN_SELFIE:
        return <MitekScanSelfieComponent countDown={countDown} debouncedIsVisible={debouncedIsVisible} hint={hint} loadingCamera={loadingCamera} mitekScienceSDK={mitekScienceSDK} setCurrentPage={setCurrentPage} state={state} />
      case EMitekPageType.SCAN_SIGNATURE:
        return <MitekScanSignatureComponent countDown={countDown} debouncedIsVisible={debouncedIsVisible} hint={hint} loadingCamera={loadingCamera} mitekScienceSDK={mitekScienceSDK} setCurrentPage={setCurrentPage} state={state} />
      case EMitekPageType.EXPLANATION_FRONT:
        return <MitekCheckFrontExplanationComponent setCurrentPage={setCurrentPage} themeColor={state.org.theme.themeColor} state={state} />
      case EMitekPageType.EXPLANATION_BACK:
        return <MitekCheckBackExplanationComponent setCurrentPage={setCurrentPage} themeColor={state.org.theme.themeColor} state={state} />
      case EMitekPageType.EXPLANATION_QR:
        return <MitekCheckQrExplanationComponent setCurrentPage={setCurrentPage} themeColor={state.org.theme.themeColor} state={state} />
      case EMitekPageType.EXPLANATION_SELFIE:
        return <MitekCheckSelfieExplanationComponent setCurrentPage={setCurrentPage} state={state} />;
      case EMitekPageType.EXPLANATION_SIGNATURE:
        return <MitekCheckSignatureExplanationComponent setCurrentPage={setCurrentPage} state={state} themeColor={state.org.theme.themeColor} />;
      case EMitekPageType.GENERAL_LOADING:
        return <MitekCheckLoadingComponent onSuccess={onSuccess} state={state} />
      case EMitekPageType.GENERAL_LOADING_DESKTOP:
        return <MitekCheckLoadingComponentDesktop onSuccess={onSuccess} state={state} />
      case EMitekPageType.GENERAL_SUCCESS:
        return <MitekCheckSuccessComponent onSuccess={onSuccess} props={props.props} state={state} />
      case EMitekPageType.GENERAL_SUBMIT:
        return <MitekSubmitComponent settings={mitekSettings} postBackend={postBackend} setCurrentPage={setCurrentPage} props={props.props} request={request} type={type} state={state} />
      case EMitekPageType.GENERAL_COMPLETED:
        return <MitekCheckCompletedComponent state={state} />
      case EMitekPageType.GENERAL_AGENT:
        return <MitekCheckAgentHandoff setResponseCallback={(e) => setResponse(e)} state={state} ctxId={state.ctxId} setCurrentPage={setCurrentPage} />
      case EMitekPageType.MANUAL_CAPTURE_FRONT:
        return <MitekCheckManualCaptureFrontComponent hint={hint} loadingCamera={loadingCamera} startManualCapture={startManualCapture} state={state} handleFileSelect={handleFileSelect} openFileBrowser={() => inputFile.current.click()} inputFile={inputFile} />
      case EMitekPageType.MANUAL_CAPTURE_BACK:
        return <MitekCheckManualCaptureBackComponent hint={hint} loadingCamera={loadingCamera} startManualCapture={startManualCapture} state={state} handleFileSelect={handleFileSelect} openFileBrowser={() => inputFile.current.click()} inputFile={inputFile} />
      case EMitekPageType.MANUAL_CAPTURE_SELFIE:
        return <MitekCheckManualCaptureSelfieComponent hint={hint} loadingCamera={loadingCamera} startManualCapture={(e) => startManualCapture(e)} state={state} />
      case EMitekPageType.MANUAL_CAPTURE_SIGNATURE:
        return <MitekCheckManualCaptureSignatureComponent hint={hint} loadingCamera={loadingCamera} startManualCapture={startManualCapture} state={state} />
      case EMitekPageType.MANUAL_CAPTURE_QR:
        return <MitekCheckManualCaptureQrComponent hint={hint} loadingCamera={loadingCamera} startManualCapture={(e) => startManualCapture(e)} state={state} handleFileSelect={handleFileSelect} openFileBrowser={() => inputFile.current.click()} inputFile={inputFile} />
      case EMitekPageType.CHECK_DESKTOP:
        return <MitekCheckCheckDesktop themeColor={state.org.theme.themeColor} state={state} />
      case EMitekPageType.ERROR:
        return <MitekErrorPage errors={response?.reasons ?? [EMitekErrorType.HARD_FAILURE]} />
      // Currently these are the same but it's not too far fetched that the props will differ in the future
      case EMitekPageType.ERROR_MISSING_DATA:
      case EMitekPageType.DESKTOP_APPEND_ERROR_MISSING_DATA:
        return <MitekMissingDataComponent combinedCheckProps={props.combinedCheckProps} onSuccess={onSuccess} response={response} setCurrentPage={setCurrentPage} state={state} />
      case EMitekPageType.DESKTOP_GENERAL:
        return <MitekCheckDesktop setResponse={(res) => setResponse(res as any)} setPageCallback={(page: EMitekPageType) => setCurrentPage(page)} ctxId={state.ctxId} lang={props.props.match.params.lang} logo={state.org.theme.logo} combinedCheckProps={props?.combinedCheckProps} />
      default:
        return <h1>Error</h1>
    }
  };

  return isDesktop ? renderPage() : (
    <Segment.Group className="containersegment" horizontal={false}>
      <Segment.Group className="mitek" style={{ height: "100%", width: "100%", margin: 0 }} basic horizontal={false}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={`${currentPage}-trans`}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            {/* Checks if currentpage is a page that uses the mitekSDK, sets display accordingly. */}
            <div className="transition-container" style={{ height: "100%", flexDirection: "column", display: [1, 2, 19].includes(currentPage) ? "block" : "flex" }}>
              {renderPage()}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Segment.Group>
    </Segment.Group>
  )
};
