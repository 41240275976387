import axios from 'axios'
import { EService } from '../Enums/EService'
import { IMitekSettings } from '../models/flow/settings/IMitekSettings'
import { IOcrLabsSettings } from '../models/flow/settings/IOcrLabsSettings'
import { getFlowByctxIdDTO, IMitekDidFinishDto, IMitekResponse, IOcrLabsAppendMissingPropertiesResponse, IOcrLabsLivenessResponse, IOcrLabsResponse, IONIAppendMissingPropertiesResponse, MitekAgentHandoffStatusDto, mitekCheckDto, OcrAppendMissingPropertiesDto, OcrLabsCheckDto, OcrLabsLivenessCheckDto, PostIBANNameCheckResponse, TransactionProgressLogDto, UtilityBillCheckDto } from '../Types'
import { EmailVerificationCheckResponseDto } from '../Types/EmailVerification/EmailVerificationResponseDto'
import { IdinCheckStatus } from '../Types/Idin/Status'

export const getFlowByctxId = (ctxId: string) => {
  return axios.get<getFlowByctxIdDTO>(`/api/frontend/flow/?ctxId=${ctxId}`);
}

export const postIdinStartService = (ctxId: string, currentBank: string) => {
  return axios.post(`/api/idinCheck/startIdin/?ctxId=${ctxId}&service=${EService.IDINCHECK}&currentBank=${currentBank}`);
}

export const getIdinCheckStatus = (ctxId: string) => {
  return axios.get<IdinCheckStatus>(`/api/idinCheck/status/?ctxId=${ctxId}`);
}

export const postEmailVerificationCheckEmail = (ctxId: string, email: string, phoneNumber: string) => {
  return axios.post(`/api/emailVerificationCheck/startService/?ctxId=${ctxId}`, { email: email, phoneNumber: phoneNumber, ctxId: ctxId, service: EService.EMAILVERIFICATIONCHECK });
}

export const postEmailVerificationCheckCode = (ctxId: string, code: string) => {
  return axios.post<EmailVerificationCheckResponseDto>(`/api/emailVerificationCheck/finishService/?ctxId=${ctxId}`, { code: code, ctxId: ctxId, service: EService.EMAILVERIFICATIONCHECK });
}

export function postIBANNameCheck(name: string, iban: string, ctxId: string) {
  return axios.post<PostIBANNameCheckResponse>(`/api/ibanCheck/startservice?ctxId=${ctxId}`, { name: name, Iban: iban, ctxid: ctxId, service: EService.IBANCHECK });
}

export function postHPGIBANNameCheck(name: string, iban: string, ctxId: string) {
  return axios.post<PostIBANNameCheckResponse>(`/api/HPGibanCheck/startservice?ctxId=${ctxId}`, { name: name, Iban: iban, ctxid: ctxId, service: EService.IBANCHECK });
}

export function postOCRIBANNameCheck(name: string, iban: string, ctxId: string) {
  return axios.post<PostIBANNameCheckResponse>(`/api/OCRIbanCheck/startservice?ctxId=${ctxId}`, { name: name, Iban: iban, ctxId: ctxId, service: EService.IBANCHECK });
}

export const postIdinAgeStartService = (ctxId: string, currentBank: string) => {
  return axios.post(`/api/idinAgeCheck/startIdinAge/?ctxId=${ctxId}&service=${EService.AGECHECK}&currentBank=${currentBank}`);
}

export const postPepCheck = (pepBody: any) => {
  return axios.post(`/api/pepCheck/startservice?ctxId=${pepBody.ctxId}`, { ...pepBody, service: EService.PEPCHECK, simpleCheck: true });
}

export const postNategisStartService = (nategisBody: any) => {
  return axios.post(`/api/nategisCheck/startservice?ctxId=${nategisBody.ctxId}`, { ...nategisBody, service: EService.NATEGISCHECK });
}

export const postCurateleCheck = (curateleBody: any) => {
  return axios.post(`/api/curateleCheck/startservice/?ctxId=${curateleBody.ctxId}`, { ...curateleBody, service: EService.CURATELECHECK });
}

export const getDidMitekFinish = (ctxId: string) => {
  return axios.get<IMitekDidFinishDto>(`/api/mitekcheck/didfinish/?ctxId=${ctxId}`);
}

export const getMitekSettings = (ctxId: string, service: number) => {
  return axios.get<IMitekSettings>(`/api/mitekcheck/getSettings/?ctxId=${ctxId}&service=${service}`);
}

export const getOcrLabsSettings = (ctxId: string, service: number) => {
  return axios.get<IOcrLabsSettings>(`/api/ocrLabs/getSettings/?ctxId=${ctxId}&service=${service}`);
}

export const getOcrLabsResults = (ctxId: string) => {
  return axios.get<IOcrLabsResponse>(`/api/ocrLabs/results/?ctxId=${ctxId}`);
}

export const postOcrLabsBsn = (ctxId: string, bsn: string) => {
  return axios.post<{ isValid: boolean }>(`/api/ocrLabs/append-bsn/?ctxId=${ctxId}`, { bsn: bsn, ctxId: ctxId });
}

export const postMitekCheck = (data: mitekCheckDto) => {
  return axios.post<IMitekResponse>(`/api/mitekCheck/startService/?ctxId=${data.ctxId}`, { ...data, service: EService.MITEKCHECK });
}

export const getOcrLabsProgress = (ctxId: string) => {
  return axios.get<TransactionProgressLogDto>(`/api/ocrLabs/status?ctxId=${ctxId}`);
}

export const postOcrLabsCheck = (data: OcrLabsCheckDto) => {
  return axios.post<IOcrLabsResponse>(`/api/ocrLabs/upload-document?ctxId=${data.ctxId}`, { ...data, service: EService.OCRLABSCHECK });
}

export const postOcrLabsLivenessCheck = (data: OcrLabsLivenessCheckDto) => {
  return axios.post<IOcrLabsLivenessResponse>(`/api/ocrlabs/upload-liveness?ctxId=${data.ctxId}`, { ...data, service: EService.OCRLABSCHECK });
}

export const postOcrLabsAppendMissingProperties = (data: OcrAppendMissingPropertiesDto) => {
  return axios.post<IOcrLabsAppendMissingPropertiesResponse>(`/api/ocrLabs/append-missing-properties?ctxId=${data.ctxId}`, { ...data, service: EService.OCRLABSCHECK });
}

export const postONIAppendMissingProperties = (data: OcrAppendMissingPropertiesDto) => {
  return axios.post<IONIAppendMissingPropertiesResponse>(`/api/onicheck/append-missing-properties?ctxId=${data.ctxId}`, { ...data, service: EService.OCRLABSCHECK });
}

export const postMitekCheckToOcr = (data: mitekCheckDto) => {
  return axios.post<IMitekResponse>(`/api/mitekcheck/startService-ocr?ctxId=${data.ctxId}`, { ...data, service: EService.MITEKCHECK });
}

export const postUtilityBillCheck = (data: UtilityBillCheckDto) => {
  return axios.post(`/api/utilityBillCheck/startservice?ctxId=${data.ctxId}`, { ...data, service: EService.UTILITYBILLCHECK });
}

export const getMitekAgentHandoffStatus = (ctxId: string) => {
  return axios.get<MitekAgentHandoffStatusDto>("/api/mitekCheck/agent-status?ctxId=" + ctxId);
}

export const postTransactionProgressLog = (dto: TransactionProgressLogDto) => {
  return axios.post('/api/transactionProgressLog/log', dto);
}

export type getFlow = {
  flow: number[],
}

// Merge frontend dashboard > TODO: Extract

// export const login = (username: string, password: string) => {
// 	return axios.post<LoginDto>("/api/authenticate/login", { username: username, password: password });
// }

// export const getAllClients = () => {
// 	return axios.get<AllClientsDto[]>("/api/adminclient/client/allclients");
// }

// export const getHomeScreenStatisticsByCustomerId = (id: number) => {
// 	return axios.get<IgetHomeScreenStatisticsByCustomerId[]>("/api/adminexcelexport/excel/recentbycustomer/" + id);
// }

// export const getAllKeys = (customerId: number) => {
// 	return axios.get<Dictionary<GetApiKeysDto[]>>(`/api/admin/keys/all/${customerId}`);
// }

// export const toggleClientActive = (id: number) => {
// 	return axios.put(`/api/adminclient/client/toggleactive/${id}`);
// }

// export const editCustomer = (customer: customer) => {
// 	return axios.put("/api/admincustomer/customer", customer);
// };

// export const createCustomer = (customer: customer) => {
// 	return axios.post("/api/admincustomer/customer", customer);
// };

// export const getCustomer = (id: number) => {
// 	return axios.get<GetCustomerDto>(`/api/admincustomer/customer/${id}`);
// };

// export const getRecentCustomers = () => {
// 	return axios.get<GetCustomerDto[]>(`/api/admincustomer/customer/recent`);
// };

// export const getAllCustomers = () => {
// 	return axios.get<getAllCustomersT[]>("/api/admincustomer/customer/all");
// }

// export const getClientsByCustomerId = (id: number) => {
// 	return axios.get<ClientsByCustomerId[]>(`/api/admincustomer/customer/clients/${id}`);
// }

// export const toggleIsActiveCustomerClient = (id: number) => {
// 	return axios.post(`/api/admincustomerclient/customerclient/toggleactive/${id}`);
// }

// export const customerService = (customerId: number, serviceId: number) => {
// 	return axios.post(`/api/admincustomer/customer/access/${serviceId}/${customerId}`);
// }

// export const getClientByCustomerClientId = (id: number) => {
// 	return axios.get<ClientCreateEditDto>(`/api/adminclient/client/${id}`);
// }

// export const uploadImage = (file: any, organizationId: string, fileName?: string) => {
// 	const formData = new FormData();
// 	formData.append("FormFile", file);
// 	formData.append("FileName", fileName || "null");
// 	formData.append("OrganizationId", organizationId);
// 	return axios.post("/api/File/image", formData);
// }

// export const createFlow = (name: string, customerClientId: number, description?: string,) => {
// 	return axios.post<FlowAddDto>('/api/adminclient/client/flow', { name: name, description: description, customerClientId: customerClientId });
// }

// export const editFlow = (name: string, id: number, order: number[], description?: string) => {
// 	return axios.put('/api/adminclient/client/flow', { name: name, description: description ?? "", id: id, order: order });
// }

// export const putClient = (client: ClientCreateEditDto) => {
// 	const expDate = client.apiKey?.expiryDate;
// 	client.apiKey = undefined;
// 	client = { ...client, apiKey: { expiryDate: expDate } };
// 	return axios.put<ClientCreateEditDto>("/api/adminclient/client", client);
// }

// export const createClient = (client: ClientCreateEditDto) => {
// 	return axios.post<ClientCreateEditDto>("/api/adminclient/client/", client);
// }

// export const createApiKeyNew = (customerclientid: number) => {
// 	return axios.post(`/api/adminclient/client/apikey/${customerclientid}`);
// }

// export const toggleIsActiveFlow = (id: number) => {
// 	return axios.put(`/api/adminclient/client/flow/${id}`);
// }

// export const getTransactionStatisticsByCustomerId = (id: number) => {
// 	return axios.get<TransactionStatistic[]>("/api/adminexcelexport/excel/bycustomer/" + id);
// }
// }
