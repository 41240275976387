import { Button, Segment } from "semantic-ui-react"
import { StateValue } from "../../../Context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export interface IOCRInformationProps {
  /**
   * Background to display
   */
  background?: string
  /** Header translation key, displayed at the top of the page, defaults to OCR_Labs.Information.Header */
  headerMessageKey?: string
  /** Sub header translation key, displayed at the top of the page, defaults to OCR_Labs.Information.SubHeader */
  subHeaderMessageKey?: string
  onCallback?: () => void
}

export const OCRInformation = (props: IOCRInformationProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (
    <div className="ocr space-between ocr-padding">
      <Segment id="explanation-screen" className="ocr" basic style={{ backgroundColor: "white", height: "90%", width: "100%", margin: 0 }}>
        <h1 className="service-item-header">{t("General_UI.Hello")}</h1>
        <h2 className="service-item-subheader">
          {t(props.headerMessageKey ?? "OCR_Labs.Information.Header")}
        </h2>
        <p>
          {t(props.subHeaderMessageKey ?? "OCR_Labs.Information.SubHeader")}
        </p>
      </Segment>
      <Segment basic className="service-item-button-container mt-0">
        <Button
          color="green"
          floated="right"
          type="submit"
          disabled={props.onCallback === undefined}
          onClick={() => props.onCallback?.()}
        >
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </div>
  )
}
