import '../App.css'
import 'semantic-ui-css/semantic.min.css'
import DesktopLayout from '../Layouts/DesktopLayout'
import MobileLayout from '../Layouts/MobileLayout'
import useLocalStorageSupportCheck from '../Hooks/useLocalStorageCheck'
import { DevLog } from '../utils/DevLog'
import { Dimmer, Loader, Step } from 'semantic-ui-react'
import { EService } from '../Enums/EService'
import { IdentityServices } from '../IdentityServices'
import { MockStepsComponent } from './layout/RenderMockSteps'
import { Start } from './Views/Start'
import { deleteCookie, setCookie } from '../utils/ReadWriteCookie'
import { getFlowByctxId } from '../Services/Backend'
import { initState, reducer, StateDispatch, StateValue } from './Context'
import { initiateStyling } from '../themeConfig'
import { isDesktop, isIPad13, isMobile, isTablet } from 'react-device-detect'
import { isDev } from '../utils/IsDev'
import { useEffect, useReducer, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useScreenSize } from '../Hooks/useScreenSize'
import { useTranslation } from 'react-i18next'

type MainComponentProps = {
  match?: any;
  history?: any;
  isPortait?: boolean,
  isLandscape?: boolean
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const MainComponent = (props: MainComponentProps) => {
  const windowSizeListener = useScreenSize();
  const [state, dispatch] = useReducer(reducer, initState);
  const { i18n } = useTranslation(state.org.theme.config.translationKey);
  const { ctxId, lang } = useParams<any>()
  // const canUseThirdPartyCookies = useCookieSupportCheck();
  const canUseLocalStorage = useLocalStorageSupportCheck();
  let query = useQuery();

  useEffect(() => {
    if (props.match.params.ctxId === undefined || props.match.params.ctxId.toString().length !== 32) {
      // Delete all information from a previous (presumably uncompleted) session.
      localStorage.clear();
      deleteCookie("ctxId");
      deleteCookie("invitationCode");
      deleteCookie("X-Api-Key");

      // Check if we come from a redirect, and set the appropiate localstorage values.
      if (query.get("identityCheck") !== "" && query.get("identityCheck") !== null && query.get("identityCheck") === "IdinNawCheck") {
        localStorage.setItem("idinRedirect", "true");
      }

      if (query.get("identityCheck") !== "" && query.get("identityCheck") !== null && query.get("identityCheck") === "IdinAgeCheck") {
        localStorage.setItem("idinAgeRedirect", "true");
      }

      if (query.get("handoff") !== "" && query.get("handoff") !== null) {
        localStorage.setItem("handoff", "true");
      }

      // Go to proper route
      props.history.push(`/${query.get("ctxId")}/${query.get("lang")}`);

    } else {
      getFlowByctxId(ctxId).then((res) => {

        i18n.changeLanguage(lang); // Change language to the one specified in the url.
        localStorage.setItem("lang", lang); // Set language in localstorage for later use.
        const data = res.data;
        var currentStepNumber = -1;
        const lastFinished = data.lastFinished;

        if (lastFinished !== null && lastFinished !== undefined && lastFinished >= currentStepNumber) {

          // Prohibits users from refreshing page after handoff and continuing on phone.
          if (props.match.params.handoff != undefined) {
            localStorage.setItem("handoff", "true");
            currentStepNumber = lastFinished;
          } else {
            currentStepNumber = lastFinished + 1;
          }
        }

        if (localStorage.getItem("idinAgeRedirect") === "true") {
          const order = data.flowOrders.find((v) => v.service === EService.AGECHECK);
          currentStepNumber = Math.max(order?.order!, Math.max(lastFinished ?? 0, currentStepNumber));
        }

        if (localStorage.getItem("idinRedirect") === "true") {
          var order = data.flowOrders.find((v) => v.service === EService.IDINCHECK);
          if (order === undefined) {
            order = data.flowOrders.find((v) => v.service === EService.MNICHECK);
          }
          currentStepNumber = Math.max(order?.order!, Math.max(lastFinished ?? 0, currentStepNumber));
        }

        if (localStorage.getItem("handoff") === "true") {
          // TODO: String
          const order = data.flowOrders.find((v) => v.service === (parseInt(props.match?.params?.service ?? "8")));
          currentStepNumber = Math.max(order?.order!, Math.max(lastFinished ?? 0, currentStepNumber));
        }

        dispatch({ type: "setLoading", data: true });

        setCookie("ctxId", props.match.params.ctxId, 100);

        const styling = initiateStyling(res.data.clientId);

        let flow = res.data.flowOrders.sort((a, b) => a.order - b.order).map((s, index) => { return { ...s, identityService: IdentityServices.find(v => v.service === s.service)!, order: index, service: s.service } });
        let current = { order: currentStepNumber, identityService: IdentityServices.find(is => is.service === EService.START)! }; // Start screen
        flow.push({ isVisible: true, order: flow.length, identityService: IdentityServices.find(is => is.service === EService.SUCCESS)!, service: EService.SUCCESS }); // Success screen

        if (currentStepNumber > -1) {
          current = flow[currentStepNumber];
        }

        dispatch({
          type: "init", data: {
            ...state,
            org: {
              ...state.org,
              id: res.data.clientId,
              theme: {
                logo: res.data.clientLogo,
                themeColor: res.data.themeColor,
                config: styling
              },
              name: res.data.clientName,
            },
            targetUrl: res.data.targetUrl,
            flow: flow,
            flowDescription: res.data.flowDescription,
            ctxId: props.match.params.ctxId,
            lastFinishedAccordingToBackend: lastFinished,
            view: "form",
            current: current,
            loading: false
          }
        });
      })
    }
  }, [props.match.params.ctxId]);

  const [didSetGTM, setDidSetGTM] = useState(false);
  useEffect(() => {
    DevLog("-- On a non local environment, we'd set Google Tag Manager here --", state?.org?.theme?.config?.gtmHead, state?.org?.theme?.config?.gtmBody)
    let shouldSetGTM = false;
    if (state?.org?.theme?.config?.gtmHead && state?.org?.theme?.config?.gtmBody && !didSetGTM && !isDev) {
      shouldSetGTM = true;
      const gtmHead = document.createElement("script");
      gtmHead.innerHTML = state?.org?.theme?.config?.gtmHead;
      document.head.appendChild(gtmHead);
    }

    if (state?.org?.theme?.config?.hotjar && !didSetGTM && !isDev) {
      shouldSetGTM = true;
      const hotjar = document.createElement("script");
      hotjar.innerHTML = state?.org?.theme?.config?.hotjar;
      document.head.appendChild(hotjar);
    }

    setDidSetGTM(shouldSetGTM);
  }, [state?.org?.theme?.config])


  const renderView = (service: EService) => {
    const currentComponent = state.flow[state.current.order].identityService!;
    switch (service) {
      case EService.IDINCHECK: return <currentComponent.component />
      case EService.IBANCHECK: return <currentComponent.component />;
      case EService.AGECHECK: return <currentComponent.component />;
      case EService.PASPOORTCHECK: return <currentComponent.component />;
      case EService.PEPCHECK: return <currentComponent.component />;
      case EService.NATEGISCHECK: return <currentComponent.component />;
      case EService.CURATELECHECK: return <currentComponent.component />;
      case EService.MITEKCHECK: return <currentComponent.component isLandscape={props.isLandscape} isPortrait={props.isPortait} props={props} />
      case EService.MNICHECK: return <currentComponent.component isLandscape={props.isLandscape} isPortrait={props.isPortait} props={props} />;
      case EService.ADDRESSCHECK: return <currentComponent.component />;
      case EService.UTILITYBILLCHECK: return <currentComponent.component />;
      case EService.MBUCHECK: return <currentComponent.component isLandscape={props.isLandscape} isPortrait={props.isPortait} props={props} />
      case EService.SUCCESS: return <currentComponent.component />;
      case EService.HPGIBANCHECK: return <currentComponent.component />;
      case EService.EMAILVERIFICATIONCHECK: return <currentComponent.component />;
      case EService.OCRLABSCHECK: return <currentComponent.component />;
      case EService.ONICheck: return <currentComponent.component props={props} />;
      case EService.OCRIBANCHECK: return <currentComponent.component />;
      default: return <></>;
    }
  };

  return !state.loading ? (

    <StateValue.Provider value={state}>
      <StateDispatch.Provider value={dispatch}>
        {
          (isDesktop && !isIPad13) && (
            <DesktopLayout
              title={state.current.identityService?.title ?? "General_UI.Start"}
              mediumScreen={windowSizeListener.mediumScreen}
              smallScreen={windowSizeListener.smallScreen}
              useLayout={state.current.identityService?.useLayout ?? true}
              sidebar={(
                <Step.Group
                  vertical={!isMobile && state.org.theme.config.verticleSteps}
                  fluid
                  unstackable={isMobile}
                  size="small"
                  className="steps-container"
                  ordered={state.org.theme.config.ordered}
                >
                  <MockStepsComponent val={state.org.theme.config.predecessingExternalSteps} />
                  {
                    state.flow.map((identityService, index) =>
                      state.current.order === identityService.order ? <state.current.identityService.sidebarComponent mediumScreen={windowSizeListener.mediumScreen} key={`${identityService.identityService.service}-SB`} flowItem={state.current} /> : <identityService.identityService.sidebarComponent mediumScreen={windowSizeListener.mediumScreen} key={`${identityService.identityService.service}-SB`} flowItem={identityService} />
                    )
                  }
                  <MockStepsComponent val={state.org.theme.config.successiveExternalSteps} />
                </Step.Group>)}>
              {state.current.order === -1 && <Start />}
              {state.current.order !== -1 && state.current.order !== state.flow.length && renderView(state.current.identityService!.service)}
            </DesktopLayout>
          )
        }
        {
          (isMobile || isTablet || isIPad13) && (
            <MobileLayout useLayout={state.current?.identityService?.useLayout ?? true} title={state.current?.identityService?.title ?? "General_UI.Start"}>
              {state.current.order === -1 && <Start />}
              {state.current.order !== -1 && renderView(state.current.identityService!.service)}
            </MobileLayout>
          )
        }
      </StateDispatch.Provider>
    </StateValue.Provider>
  ) : <div>
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  </div>;
};

