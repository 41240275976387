import { ELogType } from './Enums/ELogType'
import { EMitekCheckStatus } from './Enums/EMitekCheckStatus'
import { EMitekErrorType } from './Enums/EMitekErrorType'
import { EService } from './Enums/EService'
import { ETransactionStatusCode } from './Enums/ETransactionStatusCode'
import { IMitekSettings } from './models/flow/settings/IMitekSettings'
import { state } from './Components/Context'
import { EValidationCategory } from './Enums/EValidationCategory'
import { EComponentType } from './Enums/EComponent'
import { EValidatePropertiesCategory } from './Types/OCRLabs/Enums/EValidatePropertiesCategory'
import { EValidatePropertiesComponentType } from './Types/OCRLabs/Enums/EValidatePropertiesComponentType'
import { EOcrLabsErrorType } from './Enums/EOcrLabsErrorType'
import { NateGisCheckResponseDto } from './Types/Nategis/NateGisCheckResponseDto'

export interface ICurrentStepIframe {
  current_step: number;
  current_section: string;
}

export interface Iban {
  valid: boolean;
  found: boolean;
}

export interface IbanCheckName {
  validity: string;
}

export interface Check {
  iban: Iban;
  name: IbanCheckName;
}

export interface IbanNameMatching {
  type: string;
  nameSuggestion?: any;
  editDelta?: string;
}

export interface Account {
  foreign: string;
  countryCode: string;
  status: string;
}

export type mitekCheckDto = {
  type: "PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT",
  selfie?: string,
  IdDocuments: string[],
  ctxId: string,
  code?: string,
  wasFile: boolean,
}

export type OcrLabsCheckDto = {
  documentFront?: string,
  documentBack?: string,
  ctxId: string
}

export type OcrLabsLivenessCheckDto = {
  LivenessImage: string,
  ctxId: string
}

export type OcrAppendMissingPropertiesDto = {
  ctxId: string,
  validationFields: { [key: string]: string }
}

export interface AccountHolder {
  type: string;
  residence?: any;
  officialName: string;
  numberOfAccountHolders: number;
  jointAccount: boolean;
}

export type SidebarProps = {
  flowItem: FlowItem,
  mediumScreen: boolean,
  clientClass?: string,
}

export type FlowItem = {
  order: number,
  identityService: ServiceComponentType,
}

export interface FlowOrderDto {
  service: EService;
  order: number;
  isVisible: boolean;
}

export interface getFlowByctxIdDTO {
  targetUrl: string,
  name: string;
  clientName: string;
  clientId: number;
  clientLogo: string;
  themeColor: string;
  flowDescription: string;
  flowOrders: FlowOrderDto[];
  lastFinished?: number;
}

export interface MitekRequestBuilder {
  type: "PASSPORT" | "ID" | "DRIVERSLICENSE",
  front: any,
  back?: any,
  selfie?: any,
  code?: string,
}

export interface OCRLabsRequestBuilder {
  front?: string,
  back?: string,
}

export interface PostIBANNameCheckResponse {
  ctxid: string;
  check: Check;
  ibanNameMatching: IbanNameMatching;
  account: Account;
  accountHolder: AccountHolder;
}

export interface MitekMissingPropertiesDto {
  personalGovID?: string;
  issuingAuthority?: string;
  documentNumber?: string;
  givenNames?: string;
  surname?: string;
  dateOfIssue?: string;
  dateOfExpiry?: string;
  dateOfBirth?: string;
}

export interface MBUMissingPropertiesDto extends MitekMissingPropertiesDto {
  city?: string,
  houseNumber?: string,
  houseNumberExtension?: string,
  street?: string,
  postalCode?: string,
  gender?: string,
}


export interface MitekName {
  givenNames: string;
  surname: string;
  fullName: string;
}
export interface DynamicProperties {
  issuingAuthority: string;
  type: string;
  issuingCountry: string;
  nationality: string;
  personalGovID: string;
  nationalityCode: string;
  placeOfBirth: string;
  sex: string;
}

export interface ExtractedData {
  name: MitekName;
  address: any;
  documentNumber: string;
  dateOfExpiry?: string;
  dateOfBirth?: string;
  dateOfIssue?: string;
  dynamicProperties: DynamicProperties;
}


export interface MitekNategisIdinDidFinishDto extends IMitekDidFinishDto {
  idinNeeded?: boolean;
}

export interface IMitekDidFinishDto extends IMitekBaseResponse {
  status: ETransactionStatusCode;
}

export interface IMitekBaseResponse {
  extractedData?: ExtractedData;
  settings?: IMitekSettings;
  requiredFields?: RequiredFieldsDto;
}

export interface IMitekResponse extends IMitekBaseResponse {
  success: boolean;
  reasons?: EMitekErrorType[];
  isAgentReviewRequest: boolean;
  ctxId: string;
}

export interface IOcrLabsResponse {
  ctxId: string;
  documentValid: boolean;
  documentType: string;
  livenessRequired: boolean;
  livenessPerformed: boolean;
  livenessSuccess: boolean;
  faceMatch: boolean;
  isBackSideNeeded: boolean;
  missingFields: string[];
  errorReasons: EOcrLabsErrorType[];
  detectedFields: { [key: string]: string };
}

export interface IOcrLabsLivenessResponse {
  ctxId: string;
  livenessPerformed: boolean;
  livenessSuccess: boolean;
  faceMatches: boolean;
  errorReasons: EOcrLabsErrorType[];
}

export interface IOcrLabsAppendMissingPropertiesResponse {
  errors: AppendErrors[];
}

export interface IONIAppendMissingPropertiesResponse {
  AppendMissingResponseDto: IOcrLabsAppendMissingPropertiesResponse;
  NateGisCheckResponseDto?: NateGisCheckResponseDto
}

export interface CountryEmailDto {
  email: string,
  countryCode: string
}
export interface CountryEmailResponseDto {
  success: boolean;
}

export interface AppendErrors {
  actualEdits: number,
  allowedEdits: number,
  fieldName: string
}

export type ServiceComponentType = {
  sidebarComponent: React.ComponentType<SidebarProps>,
  component: React.ComponentType<any>,
  title: string,
  useLayout: boolean,
  service: EService,
  combinationCheckTotal?: number;
  combinationCheckCompleted?: number;
}


export interface Address {
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  municipality: string;
  province: string;
  houseNumberAdditions: any[];
  latitude: number;
  longitude: number;
}

export interface PostalCodeCheckResDto {
  status: string;
  address: Address;
  ctxId: string;
}

// export interface MitekSettings {
// 	hasPassport: boolean;
// 	hasId: boolean;
// 	hasDriversLicence: boolean;
// 	appendMissingProperties: boolean;
// 	appendMissingPropertiesAddition: boolean;
// 	appendMissingPropertiesUpdate: boolean;
// 	appendMissingPropertiesDesktop: boolean;
// 	extractedDataOnDesktop: boolean;
// 	enableSelfie: boolean;
// 	enablePassportSignature: boolean;
// 	allowExpiredDocuments: boolean;
// 	allowAgentHandoff: boolean;
// 	agentHandoffOnFrontend: boolean;
// 	documentTypeMustMatch: boolean;
// 	isCountryInclusive: boolean;
// }

export interface MitekScanPageProps {
  countDown: number;
  debouncedIsVisible: boolean;
  state: state;
  hint: string | undefined;
  setCurrentPage: (page: number) => void;
  loadingCamera: boolean;
  mitekScienceSDK: any;
}


export type AppendMissingPropertiesDto = {
  givenNames?: string,
  surname?: string,
  fullName?: string,
  documentNumber?: string,
  dateOfBirth?: string,
  dateOfIssue?: string,
  dateOfExpiry?: string,
  issuingAuthority?: string,
  personalGovID?: string,
}

export type RequiredFields = {
  mitekSettingId?: number,
  givenNames?: boolean,
  surname?: boolean,
  fullName?: boolean,
  documentNumber?: boolean,
  dateOfBirth?: boolean,
  dateOfIssue?: boolean,
  dateOfExpiry?: boolean,
  // issuingCountry?: boolean,
  nationality?: boolean,
  nationalityCode?: boolean,
  // sex?: boolean,
  issuingAuthority?: boolean,
  personalGovID?: boolean,
  // placeOfBirth?: boolean,
}
export type RequiredFieldsDto = {
  requiredFields?: RequiredFields,
  missingFields: string[]
}

export interface UtilityBillCheckDto {
  ctxId: string;
  utilityBillImageBase64: string;
  service: number;
}

export interface MitekAgentHandoffStatusDto {
  status: EMitekCheckStatus;
  mitekError: EMitekErrorType;
  didFinishDto: IMitekDidFinishDto;
}

export type TransactionProgressLogDto = {
  ctxId: string;
  logType: ELogType;
}

// Merge frontend dashboard > TODO: Extract

export type GetCustomerDto = {
  name: string;
  website: string;
  phone: string;
  email: string;
  id?: number;
};

export interface TransactionCallByDay {
  themeColor: string;
  count: number;
  clientName: string;
  date: Date;
}

export interface IgetHomeScreenStatisticsByCustomerId {
  name: string;
  themeColor: string;
  transactionCallByDay: TransactionCallByDay[];
}

export interface Dictionary<T> {
  [Key: string]: T;
}

export type GetApiKeysDto = {
  clientName?: string;
  expiryDate?: string;
  apiKey?: string;
  apiKeyId?: number;
  accessFlows?: flow[];
  customerClientId?: number;
};

export type flow = {
  id?: number;
  name: string;
  description?: string;
  flow: number[];
};

export type ClientCreateEditDto = {
  name: string;
  target: string;
  id?: number;
  customerId?: number;
  apiKey?: GetApiKeysDto;
  flows?: flow[];
  customerClientId?: number;
  logo?: any;
  themeColor?: string;
  resultCallbackBaseURLDev: string;
  resultCallbackBaseURLAcc: string;
  resultCallbackBaseURLProd: string;
  resultCallbackEndpoint: string;
};

export interface OcrLabsValidationField {
  /**
   * Unique identifier for the field, GUID
   */
  id: string;
  /**
   * Name of the field, used as key in the form data object
   */
  name: string;
  /**
   * Category of the field, used to group fields in the form
   * @see EValidatePropertiesCategory
   */
  category: EValidatePropertiesCategory;
  /**
   * Type of the field, used to render the correct form component
   * @see EComponentType
   */
  component: EValidatePropertiesComponentType;
  /**
   * Width of the field, used to calculate the width of the form component
   */
  width: number;
  /**
   * Whether the field is required or not
   */
  required: boolean;
  row: number;
  order: number;
}

export class OcrLabsValidationFieldFormValues {
  id: string = "";
  name: string = "";
  category: EValidationCategory = EValidationCategory.PERSONAL_INFORMATION;
  component: EComponentType = EComponentType.Text;
  widthPercentage: number = 100;
  required: boolean = false;
  row: number = 1;
  order: number = 1;

  constructor(init?: OcrLabsValidationField) {
    Object.assign(this, init);
  }
}