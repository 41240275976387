import axios from 'axios'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { EService } from "../../../Enums/EService"
import { IdinCheckComponent } from '../Idin/IdinCheckComponent'
import { Loader, Segment } from 'semantic-ui-react'
import { OCRLabsCheckComponent } from '../OCR/OCRCheckComponent'
import { StateDispatch, StateValue } from "../../Context"
import { TimerButton } from '../../Common/Button/TimerButton'
import { formatViewString } from '../../../Hooks/useView'
import { isDesktop } from "react-device-detect"
import { useContext, useEffect, useState } from "react"
import { useIsInitialRender } from "../../../Hooks/useIsInitialRender"
import { useTranslation } from "react-i18next"

export type ONIView = "OCRLABS" | "IDIN" | "SUCCESS" | "SUCCESS-HANDOFF" | "LOADING-DATA" | "LOADING" | "ERROR"

export type ONIStatus = {
  lastFinished: EService,
  idinNeeded?: boolean
}

const ONICheckComponent = (props: any) => {
  const state = useContext(StateValue);
  const dispatch = useContext(StateDispatch);
  // We cannot use the useView hook here as it will interfere with the data gathering and analysis of Fair Play.
  const [view, setView] = useState<ONIView>("LOADING")
  const { t } = useTranslation(state.org.theme.config.translationKey)

  const { handoff } = props.props.match.params

  const isInitialRender = useIsInitialRender();

  const ocrFinishedCallback = async () => {
    await getStatus();
    renderView();
  }

  useEffect(() => {
    if (!isInitialRender) {
      dispatch({
        type: "setCurrent",
        data: {
          ...state.current,
          identityService: {
            ...state.current.identityService,
            useLayout: isDesktop || (view !== "OCRLABS" && !handoff)
          }
        }
      })
    }
  }, [view])

  // Get status on mount only
  useEffect(() => {
    if (isInitialRender) {
      getStatus()
    }
  }, [])

  const getStatus = async () => {
    const res = await axios.get<ONIStatus>(`/api/ONICheck/status?ctxId=${state.ctxId}`)
    switch (res.data.lastFinished) {
      case EService.OCRLABSCHECK:
        setView("LOADING-DATA");
        break;
      // In reality this is where transactions on this flow will end.
      case EService.CURATELECHECK:
      case EService.NATEGISCHECK:
        if (res.data.idinNeeded === true) {
          if (handoff === undefined) {
            dispatch({
              type: "setCurrent",
              data: {
                ...state.current,
                identityService: {
                  ...state.current.identityService, combinationCheckCompleted: 1, combinationCheckTotal: 2
                }
              }
            })
            setView('IDIN')
          } else {
            setView("SUCCESS-HANDOFF")
          }
        } else if (res.data.idinNeeded === false) {
          // As we don't use the useView hook, we have to manually post the message to the parent window.
          window.parent.postMessage({ current_step: state.current.order, current_section: formatViewString("SUCCESS") }, "*");
          setView("SUCCESS")
        }
        break;
      case EService.IDINCHECK:
      case EService.MNICHECK:
        if (handoff === undefined) {
          dispatch({
            data: state.flow[state.current.order + 1],
            type: "setCurrent"
          })
          setView("SUCCESS")
        } else {
          setView("SUCCESS-HANDOFF")
        }
        break;
      default:
        setView("OCRLABS")
        break;
    }
  }

  const renderView = () => {
    switch (view) {
      case 'LOADING':
        return (
          <>
            <Segment id='loading' basic style={{ width: "100%", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Loader inverted={isDesktop} active>{t("General_UI.Loading")}</Loader>
            </Segment>
          </>
        )
      case 'ERROR':
        return <h1>Unknown error</h1>
      case 'OCRLABS':
        return <OCRLabsCheckComponent combinationService={EService.ONICheck} onCallback={ocrFinishedCallback} />
      case 'IDIN':
        return <IdinCheckComponent />
      case 'SUCCESS':
        return (
          <>
            <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${state.view}-success`} />
            <Segment basic className='service-item-button-container mt-0'>
              <TimerButton
                countDownStart={state.org.theme.config.countDownStart ?? 5}
                callback={() => { dispatch({ data: state.flow[state.current.order + 1], type: 'setCurrent' }) }} />
            </Segment>
          </>
        )
      case 'SUCCESS-HANDOFF':
        return (
          <>
            <Segment.Group className='containersegment' horizontal={false}>
              <Segment.Group className='ocrlabs' style={{ height: '100%', width: '100%', margin: 0 }} basic horizontal={false}>
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    key={`${0}-trans`}
                    addEndListener={(node, done) => {
                      node.addEventListener('transitionend', done, false);
                    }}
                    classNames="fade"
                  >
                    TODO
                  </CSSTransition>
                </SwitchTransition>
              </Segment.Group>
            </Segment.Group>
          </>
        )
      default:
        setView("OCRLABS")
    }
  }

  return (
    <>
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          {renderView()}
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default ONICheckComponent