import InformationPageLayout from '../Layouts/InformationPageLayout'
import { EMitekPageType } from '../../../../Enums/EMitekPageType'
import { EService } from '../../../../Enums/EService'
import { GetAddressFromAddressLine, GetHousenumberFromAddressLine } from '../../../../utils/SplitAddressLine'
import { IMitekResponse } from '../../../../Types'
import { isDesktop } from 'react-device-detect'
import { isLastCheck } from '../../../../utils/isLastCheck'
import { Loader } from 'semantic-ui-react'
import { MBUAppendMissingProperties } from '../../MBU/MBUAppendMissingPropertiesComponent'
import { MitekCheckAppendMissingProperties } from '../MitekCheckAppendMissingProperties'
import { MNIAppendMissingProperties } from '../../MNI/MNIAppendMissingPropertiesComponent'
import { state } from '../../../Context'

type IProps = {
  response: IMitekResponse | undefined,
  onSuccess: () => void,
  setCurrentPage: (page: number) => void,
  state: state,
  combinedCheckProps: any;
}

const MitekMissingDataComponent = (props: IProps) => {

  const renderView = (view: JSX.Element) => {
    if (isDesktop) {
      return <div id="append-screen">{view}</div>
    } else {
      return <InformationPageLayout id="append-screen">{view}</InformationPageLayout>
    }
  }

  if (props.response === undefined) return <Loader active />
  if (props.combinedCheckProps !== undefined) {
    switch (props.combinedCheckProps.service) {
      case EService.MNICHECK:
        return renderView(
          <MNIAppendMissingProperties
            mitekSettings={props.response.settings!}
            requiredAndMissingDto={props.response.requiredFields}
            data={{
              documentNumber: props.response?.extractedData?.documentNumber ?? "",
              givenNames: props.response?.extractedData?.name?.givenNames ?? "",
              surname: props.response?.extractedData?.name?.surname ?? "",
              issuingAuthority: props.response?.extractedData?.dynamicProperties?.issuingAuthority ?? "",
              personalGovID: props.response?.extractedData?.dynamicProperties?.personalGovID ?? "",
              dateOfIssue: props.response?.extractedData?.dateOfIssue ?? "",
              dateOfExpiry: props.response?.extractedData?.dateOfExpiry ?? "",
              dateOfBirth: props.response?.extractedData?.dateOfBirth ?? "",
              gender: props.response?.extractedData?.dynamicProperties?.sex ?? ""
            }}
            callback={
              () => !(localStorage.getItem("handoff") === "true")
                ? props.combinedCheckProps.callback()
                : props.setCurrentPage(EMitekPageType.GENERAL_SUCCESS)
            }
          />
        );
      case EService.MBUCHECK:
        return renderView(
          <MBUAppendMissingProperties
            mitekSettings={props.response.settings!}
            requiredAndMissingDto={props.response.requiredFields}
            data={{
              documentNumber: props.response?.extractedData?.documentNumber ?? "",
              givenNames: props.response?.extractedData?.name?.givenNames ?? "",
              surname: props.response?.extractedData?.name?.surname ?? "",
              issuingAuthority: props.response?.extractedData?.dynamicProperties?.issuingAuthority ?? "",
              personalGovID: props.response?.extractedData?.dynamicProperties?.personalGovID ?? "",
              dateOfIssue: props.response?.extractedData?.dateOfIssue ?? "",
              dateOfExpiry: props.response?.extractedData?.dateOfExpiry ?? "",
              dateOfBirth: props.response?.extractedData?.dateOfBirth ?? "",
              street: GetAddressFromAddressLine(props.response?.extractedData?.address?.addressLine1 ?? ""),
              houseNumber: GetHousenumberFromAddressLine(props.response?.extractedData?.address?.addressLine1 ?? ""),
              postalCode: props.response?.extractedData?.address?.postalCode ?? "",
              city: props.response?.extractedData?.address?.city ?? "",
              houseNumberExtension: "",
            }}
            callback={
              () => !(localStorage.getItem("handoff") === "true")
                ? props.combinedCheckProps.callback()
                : props.setCurrentPage(EMitekPageType.GENERAL_SUCCESS)
            }
          />)
      default:
        return <h1>Error</h1>
    }
  } else {
    return renderView(
      <MitekCheckAppendMissingProperties
        mitekSettings={props.response.settings!}
        requiredAndMissingDto={props.response.requiredFields}
        data={{
          documentNumber: props.response?.extractedData?.documentNumber ?? "",
          givenNames: props.response?.extractedData?.name?.givenNames ?? "",
          surname: props.response?.extractedData?.name?.surname ?? "",
          issuingAuthority: props.response?.extractedData?.dynamicProperties?.issuingAuthority ?? "",
          personalGovID: props.response?.extractedData?.dynamicProperties?.personalGovID ?? "",
          dateOfIssue: props.response?.extractedData?.dateOfIssue ?? "",
          dateOfExpiry: props.response?.extractedData?.dateOfExpiry ?? "",
          dateOfBirth: props.response?.extractedData?.dateOfBirth ?? "",
        }}
        callback={
          () => (isLastCheck(props.state) && !(localStorage.getItem("handoff") === "true"))
            ? props.onSuccess()
            : props.setCurrentPage(EMitekPageType.GENERAL_SUCCESS)
        }
      />)
  }
}

export default MitekMissingDataComponent