import Webcam from 'react-webcam';
import timericonBlack from '../../../../Assets/camera-timer-icon.png'
import timericonWhite from '../../../../Assets/camera-timer-icon-white.png'
import useCountdown from '../../../../Hooks/useCountdown';
import useInterval from '../../../../Hooks/useInterval';
import { Button, Header, Icon, Image, Popup, Segment } from 'semantic-ui-react';
import { ELogType } from '../../../../Enums/ELogType';
import { EOCRLabsPageType } from '../../../../Types/OCRLabs/Enums/EOCRLabsPageType';
import { OCRLabsContext } from '../useOCR';
import { StateValue } from '../../../Context';
import { postOcrLabsLivenessCheck, postTransactionProgressLog } from '../../../../Services/Backend';
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

export type OCRTakeSelfieProps = {
  hasErrored: boolean,
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

export const OCRTakeSelfie = (props: OCRTakeSelfieProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { setPage, settings, response, onDocumentValidated } = useContext(OCRLabsContext);
  const [size, setSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 });
  const [isFlash, setIsFlash] = useState<boolean>(true);
  const options = [0, 2, 5];
  const [countdownOption, setCountdownOption] = useState<number>(options[1]);
  const [count, setCount] = useState(0);

  const sizeRef = useRef<HTMLDivElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [isCounting, setIsCounting] = useState(false);
  const { currentCount, startCountdown } = useCountdown({
    count: countdownOption,
    onComplete: () => {
      setIsCounting(false);
      capture();
    },
  });

  const handleStartCountdown = () => {
    setIsCounting(true);
    startCountdown();
  };

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckEnteredSelfie })
  }, [])

  useEffect(() => {
    const { current: box } = sizeRef;
    if (!box) return;

    const handleResize = () => {
      const { width, height } = box.getBoundingClientRect();
      setSize({ width, height });
    };

    handleResize();
    box.addEventListener('resize', handleResize);

    return () => {
      box.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectCountDownOption = () => {
    const index = options.indexOf(countdownOption);
    const newIndex = index + 1 >= options.length ? 0 : index + 1;
    setCountdownOption(options[newIndex]);
  }

  const capture = useCallback(() => {
    if (webcamRef?.current == null) return;
    const imageSrc = webcamRef.current.getScreenshot();
    handleCapture(imageSrc);
  }, [webcamRef]);

  const onRetry = () => {
    setPage({
      page: EOCRLabsPageType.TakeSelfie,
      data: { hasErrored: true },
    })
  }

  const handleFullRetry = () => {
    setPage({
      page: EOCRLabsPageType.TakePicture,
      data: { isBackside: false },
      renderButtonContainer: false,
    });
  }

  const handleCapture = (img: any) => {
    const isHandoff = window.localStorage.getItem("handoff") == "true";
    setPage({
      page: EOCRLabsPageType.Loading,
      data: {
        headerMessageKey: "OCR_Labs.Loading.Automatic_Refresh_Header" + (isHandoff ? "_Handoff" : ""),
        subHeaderTranslationKey: "OCR_Labs.Loading.Automatic_Refresh_SubHeader",
      },
      renderButtonContainer: false
    })

    postOcrLabsLivenessCheck({ ctxId: state.ctxId, LivenessImage: img }).then((livenessResponse) => {
      let data = livenessResponse.data;
      // eslint-disable-next-line no-constant-condition
      if (data.livenessSuccess && data.faceMatches) {
        if (onDocumentValidated !== undefined) {
          sessionStorage.setItem(`ocr-document-validated-${state.ctxId}`, "true")
          postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckHandoffCompleted });
          onDocumentValidated(response);
        } else {
          setPage({
            page: EOCRLabsPageType.ValidateProperties,
            data: {
              fields: settings!.ocrLabsValidationFields,
              detectedFields: response!.detectedFields,
            },
            renderButtonContainer: false
          });
        }
      } else {
        // en anders (1 === res.data.errorCode)
        setPage({
          page: EOCRLabsPageType.TakeSelfie,
          data: { hasErrored: true },
        })
      }
    }).catch(() => {
      setPage({
        page: EOCRLabsPageType.Error,
        renderButtonContainer: false,
        data: {
          messageKeys: ["OCR_Labs.Error.Header"],
          headerTranslationKey: "OCR_Labs.Error.Header",
          messageHeaderTranslationKey: "OCR_Labs.Error.MessageHeader_Selfie",
          onCallback: () => onRetry(),
        }
      })
    })
  };

  const handleFileSelect = async (e: any) => {
    var files = e.target.files as FileList;
    if (files[0].size > 5242880) {
      // setHint(t("Mitek.File_Too_Big"));
    } else {
      const promise = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      handleCapture(promise);
    }
  }

  return (
    <div className="ocr space-between" style={{ backgroundColor: isFlash ? "white" : "black", alignItems: "center", overflow: 'hidden' }}>
      <span style={{ paddingLeft: "1.5em", paddingRight: "1.5em", paddingBottom: "1em", position: "absolute", backgroundColor: "#00000060", backdropFilter: "blur(4px)", zIndex: 999 }} id="selfie-header">
        <Header as="h1" className="service-item-header" style={{ color: isFlash ? "black" : "white", margin: 0, marginBottom: 0 }}>
          {!props.hasErrored ? t("OCR_Labs.TakeSelfie.Header") : t(`OCR_Labs.Error.300`)}
          <Header.Subheader>
            {t("OCR_Labs.TakeSelfie.SubHeader")}
          </Header.Subheader>
        </Header>
      </span>
      <div style={{ position: "absolute", zIndex: 1, top: `46%`, right: "2.5em", display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "10px" }}>
        <div
          style={{ minWidth: "70px", paddingLeft: "1em", paddingRight: "1em", paddingTop: "0.25em", paddingBottom: "0.25em", borderRadius: "5px", backgroundColor: isFlash ? "black" : "white", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", justifyContent: "center" }}
          onClick={selectCountDownOption}
        >
          <Header as="h4" style={{ fontWeight: "bold", color: isFlash ? "white" : "black", margin: 0 }}>
            {countdownOption}
          </Header>
          <Image
            src={isFlash ? timericonWhite : timericonBlack}
            style={{ width: "24px", height: "24px", marginLeft: "0.5em" }}
          />
        </div>
        <div
          style={{ minWidth: "70px", paddingLeft: "1em", paddingRight: "1em", paddingTop: "0.25em", paddingBottom: "0.5em", borderRadius: "5px", backgroundColor: isFlash ? "black" : "white", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", justifyContent: "center" }}
          onClick={() => setIsFlash(!isFlash)}
        >
          <Icon
            name="lightning"
            style={{ color: isFlash ? "white" : "black", margin: 0 }}
          />
        </div>
      </div>
      {(isCounting && currentCount <= 1) &&
        <div style={{ width: "100vw", height: "100vh", zIndex: 999, backgroundColor: "white", position: "absolute" }} />
      }

      <div style={{ flex: 1, width: "calc(100% - 3em)", overflow: "hidden", marginTop: "1.5em" }} ref={sizeRef}>
        {size.width !== 0 && size.height !== 0 && (
          <Webcam
            id='webcam'
            audio={false}
            height={size.height}
            forceScreenshotSourceSize
            imageSmoothing={false}
            ref={webcamRef}
            mirrored={true}
            screenshotFormat="image/jpeg"
            width={size.width}
            videoConstraints={videoConstraints}
            style={{
              width: '100%', // Set the width to 100% of the container
              height: 'auto', // Set the height to auto to maintain aspect ratio
              display: 'block', // Set display to block to remove any whitespace
            }}
          />
        )}
      </div>
      <input onChange={(e) => handleFileSelect(e)} accept="image/*" type='file' id='file' ref={inputFileRef} style={{ display: 'none' }} />
      <Segment basic className="service-item-button-container mt-0" style={{ width: "100%" }}>
        {(props.hasErrored && count > 4) && (
          <Popup
            content={t("OCR_Labs.TakeSelfie.Different_Document")}
            open={count > 4}
            trigger={
              <Button
                primary color="green"
                floated="right"
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={handleFullRetry}>
                {t("General_UI.Retry")}
              </Button>
            } />
        )}
        <div style={{ padding: "1.5em", width: "100%" }}>
          <Button
            color="green"
            floated="right"
            type="submit"
            onClick={handleStartCountdown}
            disabled={isCounting}
            style={{ width: "100%" }}
          >
            {isCounting ? currentCount : t("OCR_Labs.TakeSelfie.Button")}
          </Button>
        </div>
      </Segment>
    </div>
  )
}
