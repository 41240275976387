import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Header, Segment } from "semantic-ui-react";
import { ELogType } from "../../../../Enums/ELogType";
import { postTransactionProgressLog } from "../../../../Services/Backend";
import { StateDispatch, StateValue } from "../../../Context";

export interface OCRSuccessProps {
  headerTranslationKey?: string;
  subHeaderTranslationKey?: string;
}

export const OCRSuccess = (props: OCRSuccessProps) => {
  const { headerTranslationKey, subHeaderTranslationKey } = props;
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const dispatch = useContext(StateDispatch);
  const isHandoff = localStorage.getItem("handoff") === "true";
  const onSuccess = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckCompleted });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  return (
    <div className="service-item-container ocr space-between ocr-padding">
      <Header as="h1" className="service-item-header">
        {t(headerTranslationKey ?? "OCR_Labs.Success.Header")}
        <Header.Subheader as="h2" className="service-item-subheader">
          {t(subHeaderTranslationKey ?? "OCR_Labs.Success.SubHeader")}
        </Header.Subheader>
      </Header>

      <Segment basic className="service-item-button-container mt-0">
        <Button className="full-width" color="green" floated="right" onClick={() => onSuccess()} disabled={isHandoff}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </div>
  )
}
