import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { ELogType } from '../../../../Enums/ELogType';
import { postTransactionProgressLog } from '../../../../Services/Backend';
import { EOCRLabsPageType } from '../../../../Types/OCRLabs/Enums/EOCRLabsPageType';
import { StateValue } from '../../../Context';
import { OCRLabsContext } from '../useOCR';

export type OCRTakePictureProps = {
  isBackside: boolean,
}

export const OCRTakePicture = (props: OCRTakePictureProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const { request, setRequest, setPage } = useContext(OCRLabsContext);

  const [hint, setHint] = React.useState<string | null>(null);

  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const captureFileRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {

    postTransactionProgressLog({ ctxId: state.ctxId, logType: !props.isBackside ? ELogType.OCRLabsCheckEnteredFirstScan : ELogType.OCRLabsCheckEnteredSecondScan })
  }, [])


  const appendToRequest = (img: any, isBackside: boolean) => {
    setRequest(isBackside ? { ...request, back: img.response.imageData } : { ...request, front: img.response.imageData });
    if (!isBackside) {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckTookFirstPicture })
      setPage({ page: EOCRLabsPageType.ConfirmAndSubmitPicture, renderButtonContainer: false })
    } else {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckTookSecondPicture })
      setPage({ page: EOCRLabsPageType.ConfirmAndSubmitPicture, renderButtonContainer: false })
    }
  };

  const handleFileSelect = async (e: any, isBackside: boolean) => {
    var files = e.target.files as FileList;
    if (files[0].size > 5242880) {
      setHint(t("Mitek.File_Too_Big"));
    } else {
      const promise = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
      appendToRequest({ response: { imageData: promise } }, isBackside);
    }
  }

  const openFileBrowser = (file: boolean) => {
    if (file) {
      if (inputFileRef.current) {
        inputFileRef.current.click();
      }
    }
    if (!file) {
      if (captureFileRef.current) {
        captureFileRef.current.click();
      }
    }
  }

  return (
    <div className="ocr space-between ocr-padding" style={{ paddingBottom: '1em' }}>
      <Header as="h1" className="service-item-header">
        {t("OCR_Labs.TakePicture.Header", { side: props.isBackside ? t("OCR_Labs.TakePicture.Back") : t("OCR_Labs.TakePicture.Front") })}
        <Header.Subheader>
          {t("OCR_Labs.TakePicture.SubHeader")}
        </Header.Subheader>
      </Header>

      {hint !== null && hint !== undefined && hint.length > 2 && (
        <h2>
          {hint}, {t("General_UI.Could_You_Try_Again").toLowerCase()}
        </h2>
      )}

      <div className='ocr-take-picture' style={{ overflowY: "auto" }}>
        <Segment
          placeholder className="service-item-button-container mt-0"
          style={{ padding: "1em", minHeight: "14rem" }}
        >
          <Header icon>
            <Icon
              style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}
              fitted
              name='camera'
            />
          </Header>
          <Button
            color="green"
            floated="right"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={() => openFileBrowser(false)}
          >
            {t("OCR_Labs.TakePicture.Take_Picture")}
          </Button>
          <input capture="environment" accept="image/*" onChange={(e) => handleFileSelect(e, props.isBackside)} type='file' id='file-capture' ref={captureFileRef} style={{ display: 'none' }} />
        </Segment>
        <Segment placeholder className="service-item-button-container mt-0" style={{ padding: "1em", minHeight: "14rem" }}>
          <Header icon>
            <Icon
              style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}
              fitted
              name='file image outline'
            />
          </Header>
          <Button
            color="green"
            floated="right"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClick={() => openFileBrowser(true)}
          >
            {t("OCR_Labs.TakePicture.Select_File")}
          </Button>
          <input
            onChange={(e) => handleFileSelect(e, props.isBackside)}
            accept="image/*"
            type='file'
            id='file'
            ref={inputFileRef}
            style={{ display: 'none' }}
          />
        </Segment>
      </div>
    </div>
  )
}
