import OCRDynamicValidatePropertiesComponent, { Ocr_FormData } from "./AppendMissingProperties/OCRDynanicValidatePropertiesComponent"
import { EService } from "../../../Enums/EService"
import { OCRLabsContext } from "./useOCR"
import { OcrLabsValidationField } from "../../../Types"
import { isDesktop } from "react-device-detect"
import { postONIAppendMissingProperties } from "../../../Services/Backend"
import { useContext } from "react"

export interface OcrLabsDynamicValidationFieldResponse {
  fields: OcrLabsValidationField[];
  detectedFields: { [key: string]: string }
  onCallback?: (data: any) => void;
}

export const OCRDynamicPropertiesComponent = (props: OcrLabsDynamicValidationFieldResponse) => {
  const { combinationService, onCallback } = useContext(OCRLabsContext);

  const renderView = (view: JSX.Element) => {
    if (isDesktop) {
      return <div id="append-screen">{view}</div>
    } else {
      return <>{view}</>
    }
  }

  const ONIBackendCall = async (data: { ctxId: string; validationFields: Ocr_FormData }) => {
    return await postONIAppendMissingProperties(data);
  }

  // if (props.response === undefined) return <Loader active />
  if (combinationService !== undefined) {
    switch (combinationService) {
      case EService.ONICheck:
        return renderView(
          <OCRDynamicValidatePropertiesComponent
            detectedFields={props.detectedFields}
            fields={props.fields}
            onCallback={onCallback ?? props.onCallback}
            onBackendCall={ONIBackendCall}
          />
        )
      default:
        return <h1>Error</h1>
    }
  } else {
    return renderView(
      <OCRDynamicValidatePropertiesComponent
        detectedFields={props.detectedFields}
        fields={props.fields}
        onCallback={props.onCallback}
      />
    )
  }
}