import { createContext } from "react";
import { Loader } from "semantic-ui-react";
import { IOcrLabsSettings } from "../../../models/flow/settings/IOcrLabsSettings";
import { IOcrLabsAppendMissingPropertiesResponse, IOcrLabsResponse, OCRLabsRequestBuilder } from "../../../Types";
import { EOCRLabsPageType } from "../../../Types/OCRLabs/Enums/EOCRLabsPageType";
import { OCRLabsCurrentPage } from "../../../Types/OCRLabs/OCRLabsCurrentPage";
import { EService } from "../../../Enums/EService";

const initialPageState: OCRLabsCurrentPage = { renderButtonContainer: false, page: EOCRLabsPageType.Loading, data: {} };

export const OCRLabsContext = createContext<IOCRLabsContext>({
  onPropertiesValidated: undefined,
  onDocumentValidated: undefined,
  setOnPropertiesValidated: () => { },
  setOnDocumentValidated: () => { },
  currentPage: initialPageState,
  setPage: () => { },
  settings: undefined,
  setSettings: () => { },
  request: { front: undefined, back: undefined },
  setRequest: () => { },
  response: undefined,
  setResponse: () => { },
});

type IOCRLabsContext = {
  onDocumentValidated?: (e: any) => (e: any) => void;
  onPropertiesValidated?: (e: any) => (e: any) => void;
  setOnDocumentValidated: (callback: ((response: IOcrLabsResponse) => void) | undefined) => void;
  setOnPropertiesValidated: (callback: ((response: IOcrLabsAppendMissingPropertiesResponse) => void) | undefined) => void;
  currentPage: OCRLabsCurrentPage;
  setPage: (page: OCRLabsCurrentPage) => void;
  settings: IOcrLabsSettings | undefined;
  setSettings: (settings: IOcrLabsSettings | undefined) => void;
  request: OCRLabsRequestBuilder;
  setRequest: (request: OCRLabsRequestBuilder) => void;
  response: IOcrLabsResponse | undefined;
  setResponse: (response: IOcrLabsResponse) => void;
  combinationService?: EService;
  onCallback?: () => void;
};